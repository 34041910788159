import React from "react"
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  Link,
} from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import YaraLogo from "../assets/images/yara-logo.svg"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <Box width="full" backgroundColor="ahuaBlue.90" color="white">
      <Box maxWidth="6xl" mx="auto" px="6" py={{ base: 10, md: 16 }}>
        <Grid
          alignItems="center"
          width="full"
          templateColumns={{ base: "unset", md: "repeat(2, 1fr)" }}
          rowGap="6"
        >
          <GridItem>
            <HStack spacing="6">
              <Image
                alignSelf={{ base: "flex-start", md: "center" }}
                src={YaraLogo}
                boxSize={{ base: "50px", md: "60px" }}
                alt="yara logo"
              />
              <Text
                fontSize="sm"
                dangerouslySetInnerHTML={{
                  __html: t("layout.footer.copyright", {
                    currentYear: new Date().getFullYear(),
                  }),
                }}
              ></Text>
            </HStack>
          </GridItem>
          <GridItem
            rowStart={{ base: 1, md: "unset" }}
            justifySelf={{ base: "self-start", md: "self-end" }}
            width="full"
          >
            <HStack
              spacing="6"
              justifyContent={{ base: "space-between", md: "flex-end" }}
              width="full"
            >
              <Link
                href="https://www.yara.com/privacy-and-legal/digital-farming-privacy/"
                isExternal
              >
                {t("layout.footer.privacy-policy")}
              </Link>
              <Link
                href="https://www.yara.com/privacy-and-legal/digital-farming-terms/"
                isExternal
              >
                {t("layout.footer.term-of-use")}
              </Link>
            </HStack>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}

export default Footer
