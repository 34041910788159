import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Flex, Heading } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import AppStoreButtons from "./AppStoreButtons"
import useAvailableRegion from "../../hooks/useAvailableRegion"
import { headerHeight } from "../../utils/constant"

const Overlay = () => (
  <Box
    position="absolute"
    width="full"
    height="full"
    backgroundColor="#0F0F0F"
    opacity="0.55"
  />
)

const BackgroundImage = ({ data }) => (
  <Box
    as={GatsbyImage}
    loading="eager"
    image={getImage(data.bannerBackgroundImage)}
    position="absolute !important"
    width="full"
    height="full"
    alt="yara farmcare field background"
  />
)

const query = graphql`
  {
    bannerBackgroundImage: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "banner/background.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    bannerPhonePreview: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "banner/phone-preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`

const Banner = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query)
  const { loading, available } = useAvailableRegion()

  return (
    <Flex
      as="section"
      width="full"
      position="relative"
      alignItems="center"
      minHeight={`calc(100vh - ${headerHeight})`}
    >
      <BackgroundImage data={data} />
      <Overlay />
      <Flex
        position="relative"
        width="full"
        height="full"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          direction={{ base: "column", sm: "row" }}
          alignItems="center"
          mx="auto"
          maxW="6xl"
          px="6"
          width="full"
          height="full"
        >
          <Flex
            color="white"
            direction="column"
            width={{ base: "100%", sm: "65%" }}
            py="10"
            pr={{ base: "0", sm: "6" }}
          >
            <Heading
              fontWeight="300"
              textAlign={{ base: "center", sm: "inherit" }}
              fontSize={{ base: "2rem", md: "3.5rem" }}
              lineHeight={{ base: "3rem", md: "4rem" }}
              py="6"
            >
              {t("home-page.banner-section.title")}
            </Heading>
            <AppStoreButtons
              chakraStyleProps={{
                mt: "4",
                justifyContent: { base: "center", sm: "flex-start" },
                spacing: "6",
              }}
              naChakraStyleProps={{
                mt: "4",
                alignItems: { base: "center", sm: "flex-start" },
                spacing: "3",
              }}
              loading={loading}
              available={available}
            />
          </Flex>

          <Flex
            width={{ base: "100%", sm: "35%" }}
            justifyContent="center"
            p="8"
          >
            <Box
              as={GatsbyImage}
              image={getImage(data.bannerPhonePreview)}
              width="full"
              alt="yara farmcare app"
              pointerEvents="none"
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Banner
