import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Banner from "../components/HomePage/Banner"
import KeyFeatures from "../components/HomePage/KeyFeatures"
import HowItWorks from "../components/HomePage/HowItWorks"
import Testimonial from "../components/HomePage/Testimonial"
import CallToAction from "../components/HomePage/CallToAction"

const HomePage = () => {
  return (
    <Layout>
      <Banner />
      <KeyFeatures />
      <HowItWorks />
      <Testimonial />
      <CallToAction />
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
